<template>
  <div>
    <section class="px-4 section">
      <h1 class="mb-8 text-3xl font-semibold text-blue-900 dark:text-gray-400">
        <span v-if="archived">
          Archived
        </span>
        <span v-else>
          Active
        </span>
        Due Diligences
      </h1>
      <div v-if="clients.length > 0">
        <ul class="grid grid-cols-1 gap-1 sm:gap-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <li v-for="client in splitedList" :key="client.client" class="flex col-span-1">
            <router-link class="flex w-full col-span-1 px-2 py-1" :to="clientLink(client.client_name)">
              <!-- :to="'/client/' + client.client_name" -->
              <div
                class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-bold text-white uppercase shadow rounded-l-md"
                :class="client.client_name[0] == 'dd' ? 'bg-blue-600' : 'bg-green-400'"
              >
                {{ client.client_name[0] }}
              </div>

              <div
                class="flex items-center justify-between flex-1 truncate bg-white shadow dark:bg-gray-500 rounded-r-md"
              >
                <div class="flex-1 px-2 py-2 text-sm">
                  <span
                    v-for="(name, index) in client.client_name"
                    :key="index"
                    class="text-gray-700 truncate dark:text-gray-300 "
                  >
                    <span v-if="index > 0" class="mr-1 uppercase ">{{ name }}</span>
                  </span>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div v-else-if="loading"></div>
      <div v-else>
        There are no active engagements
      </div>
    </section>
  </div>
</template>

<script>
  //   import db from "../firebaseInit";
  import { repgen } from "@/firebase";
  import { collection, getDocs, query, where } from "firebase/firestore"
  export default {
    name: "Clients",
    props: ["archived"],
    data() {
      return {
        clients: [],
        loading: true,
        newClientName: [],
      };
    },

    mounted() {
      const q = query(collection(repgen, "swots"), where("archive", "==", this.archived));
      getDocs(q).then((querySnapshot) => {
          this.loading = false;
          querySnapshot.forEach((doc) => {
            let data = {
              client_name: doc.id,
              publishedAt: doc.data().publishedAt,
              archive: doc.data().archive,
            };
            this.clients.push(data);
          });
        });
        
    },
    computed: {
      arrangeChannelName: function(channelName) {
        return channelName.split("-");
      },
      splitedList() {
        let newArr = [...this.clients];
        newArr.map((el) => {
          return (el.client_name = el.client_name.split("-"));
        });
        return newArr;
      },
    },
    methods: {
      clientLink(linkArray) {
        let linkString = null;

        if (this.archived) {
          linkString = "/swot/archived/";
        } else {
          linkString = "/swot/client/";
        }
        for (var i = 0; i < linkArray.length; i++) {
          linkString += linkArray[i];
          if (i != linkArray.length - 1) {
            linkString += "-";
          }
        }
        return linkString;
      },
    },
  };
</script>

<style scoped>
  .button {
    float: left;
    margin: 0 15px 0 0;
  }
</style>
